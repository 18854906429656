import { Controller } from '@hotwired/stimulus'
import GLightbox from 'glightbox'

export default class extends Controller {
  static values = { group: String }

  connect() {
    const options = { type: 'image' }
    if (this.hasGroupValue) {
      this.element.classList.add(`glightbox-group-${this.groupValue}`)
      options.selector = `.glightbox-group-${this.groupValue} .glightbox`
    }
    this.lightbox = GLightbox(options);
    document.addEventListener('turbo:before-render', this.clean)
  }

  disconnect() {
    this.lightbox.settings.closeEffect = 'none'
    this.lightbox.destroy()
    this.lightbox = undefined
    document.removeEventListener('turbo:before-render', this.clean)
  }

  clean() {
    document.body.classList.remove(
      'glightbox-open',
      'touching',
      'gdesc-open',
      'glightbox-touch',
      'glightbox-mobile',
      'gscrollbar-fixer'
    )
  }
}
