import { Controller } from '@hotwired/stimulus'
import Swiper from 'swiper'

export default class extends Controller {
  connect() {
    this.swiper = new Swiper(this.element, {
      allowTouchMove: false,
      autoplay: { delay: 0.01 },
      loop: true,
      loopedSlides: 8,
      slidesPerView: 'auto',
      speed: 5000,
      breakpoints: {
        768: {
          speed: 8000
        }
      }
    })
  }

  disconnect() {
    this.swiper.destroy()
    this.swiper = undefined
  }
}
