import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'link' ]

  change(event) {
    const select = event.currentTarget
    const index = select.selectedIndex
    if (index === undefined) return
    this.linkTarget.href = select.options[index].value
    this.linkTarget.click()
  }
}
