import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  click(event) {
    event.preventDefault()

    const target = document.getElementById(event.currentTarget.hash.split('#')[1])
    if(!target) return;

    const headerHeight = window.innerWidth < 768 ? 64 : 80
    window.scrollTo({
      top: target.getBoundingClientRect().top + window.pageYOffset - headerHeight - 10,
      behavior: 'smooth'
    })
  }
}
