import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    if (typeof(gtag) == 'function') {
      const path = window.location.pathname
      const params = window.location.search
      gtag('config', 'UA-152360032-7', { 'page_path': path + params });
      gtag('config', 'G-E21N9HR0H4', { 'page_path': path + params });
    }
  }
}
