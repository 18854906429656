// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import { Turbo } from "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import '../stylesheets/application.css'
import 'swiper/swiper.min.css'
import 'swiper/modules/autoplay/autoplay.min.css'
import 'glightbox/dist/css/glightbox.css'
import gtag from './analytics'
const images = require.context('../images', true)

Rails.start()
ActiveStorage.start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"

// Adobe Fonts
import "./adobe-fonts"

document.addEventListener('turbo:load', (event) => {
  const links = document.querySelectorAll('a')
  if (links.length === 0) return
  links.forEach(link => {
    const category = link.dataset.gaEventCategory
    const action = link.dataset.gaEventAction
    const label = link.dataset.gaEventLabel
    if (category !== undefined) {
      link.addEventListener('click', (event) => {
        event.preventDefault()
        const url = event.currentTarget.href
        gtag('event', action, {
          'event_category': category,
          'event_label': label,
          'non_interaction': true
        })
        if (link.getAttribute('href') !== '#') {
          if (link.getAttribute('href').startsWith('http')) {
            window.open(url, '_blank')
          } else {
            Turbo.visit(url)
          }
        }
      })
    }
  })
})
