import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'tab', 'panel' ]
  static values = { activeIndex: Number }

  click(event) {
    this.activeIndexValue = this.tabTargets.indexOf(event.currentTarget)
    event.preventDefault()
  }

  activeIndexValueChanged() {
    this.tabTargets.forEach((el, index) => {
      if (el.dataset.disabled === undefined) {
        if (index === this.activeIndexValue) {
          el.classList.remove('text-gray-700', 'border-gray-200')
          el.classList.add('text-red-400', 'border-red-200')
        } else {
          el.classList.remove('text-red-400', 'border-red-200')
          el.classList.add('text-gray-700', 'border-gray-200')
        }
      }
    })

    this.panelTargets.forEach((el, index) => {
      if (index === this.activeIndexValue) {
        el.classList.remove('hidden')
        el.classList.add('block')
      } else {
        el.classList.remove('block')
        el.classList.add('hidden')
      }
    })
  }
}
