window.dataLayer = window.dataLayer || [];
function gtag() { dataLayer.push(arguments); }
gtag('js', new Date());

document.addEventListener("turbo:load", () => {
  if (typeof(gtag) == "function") {
    gtag('config', 'UA-152360032-7', {
      page_title: document.title,
      page_path: location.href.replace(location.origin, ""),
    });
    gtag('config', 'G-E21N9HR0H4', {
      page_title: document.title,
      page_path: location.href.replace(location.origin, ""),
    });
  }
});

export default gtag
