import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'button', 'panel' ]

  connect() {
    document.addEventListener('turbo:before-render', () => { this.close() })
  }

  disconnect() {
    document.removeEventListener('turbo:before-render', () => { this.close() })
  }

  open() {
    this.buttonTarget.classList.remove('translate-x-0')
    this.buttonTarget.classList.add('translate-x-full')

    this.panelTarget.classList.remove('translate-x-full')
    this.panelTarget.classList.add('translate-x-0')
  }

  close() {
    this.buttonTarget.classList.remove('translate-x-full')
    this.buttonTarget.classList.add('translate-x-0')

    this.panelTarget.classList.remove('translate-x-0')
    this.panelTarget.classList.add('translate-x-full')
  }
}
