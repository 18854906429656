import { Controller } from '@hotwired/stimulus'
import Swiper from 'swiper'

export default class extends Controller {
  static targets = [ 'container', 'card' ]

  connect() {
    const count = this.cardTargets.length
    this.swiper = new Swiper(this.containerTarget, {
      slidesPerView: 'auto',
      loop: count > 1,
      loopedSlides: count,
      centeredSlides: true,
      spaceBetween: 20,
      breakpoints: {
        768: {
          spaceBetween: 30,
        }
      }
    })
  }

  disconnect() {
    this.swiper.destroy()
    this.swiper = undefined
  }
}
