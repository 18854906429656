import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ["galleries"]

  change(event) {
    let selected = event.target.selectedOptions[0].value

    get(`/admin/reports/galleries?area_id=${selected}`, {
      responseKind: 'turbo-stream'
    })
  }
}
