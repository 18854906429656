import { Controller } from '@hotwired/stimulus'
import Swiper, { Autoplay } from 'swiper';

export default class extends Controller {
  static targets = [ 'container', 'image' ]

  initialize() {
    Swiper.use([Autoplay])
  }

  connect() {
    if (this.imageTargets.length < 2) return;

    this.swiper = new Swiper(this.containerTarget, {
      loop: true,
      autoplay: {
        delay: 5000,
      },
    });
  }

  disconnect() {
    if (this.swiper) {
      this.swiper.destroy()
      this.swiper = undefined
    }
  }
}
