import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import { patch } from "@rails/request.js"

export default class extends Controller {
  static values = { url: String }

  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
    const id = event.item.dataset.id
    const url = this.urlValue.replace(":id", id)
    const position = event.newIndex + 1

    patch(url, {
      body: JSON.stringify({ position })
    })
  }
}
